import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <h1>Coming Soon...</h1>
          <p>
            This project is still being actively developed. If you would like to
            know more about my work in{' '}
            <a
              href="https://golang.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Golang
            </a>{' '}
            and{' '}
            <a
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              React
            </a>{' '}
            for this project, please reach out to me :)
          </p>
          <ul className="actions">
            <li>
              <Link to="/#work" className="button">
                Back
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
